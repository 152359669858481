<template>
    <section>
        <div class="row mx-0 my-2">
            <div class="col d-middle">
                <div class="br-20 w-100 bg-general2 text-white text-center py-1">
                    {{ productos.length }} Productos en kit
                </div>
            </div>
            <div class="col-auto">
                <el-tooltip placement="bottom" content="Añadir Producto" effect="light">
                    <div v-if="disableInCedis" class="bg-general br-10 cr-pointer shadow px-2 py-2 text-white" @click="modalAgregar()">
                        <i class="icon-plus f-18" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 292px)">
            <div class="row mx-0 justify-center my-2">
                <div id="loading-productos" class="col-xl-auto col-lg col-sm">
                    <div v-for="(producto, index) in productos" :key="index" class="d-flex mb-3">
                        <div class="col-auto px-0">
                            <img :src="producto.imagen_hijo" width="89" height="89" class="obj-cover" />
                        </div>
                        <div class="col">
                            <p class="text-general f-14 nombre f-500">
                                {{ producto.nombre_hijo }}
                            </p>
                            <p class="text-general2 f-14 my-1">
                                {{ producto.presentacion_hijo }}
                            </p>  
                            <div class="d-middle mx-0">
                                <div class="col-7 px-0">
                                    <p class="col pl-0 text-general2 f-14">
                                        <b class="text-general">{{ producto.cantidad }}</b>
                                        Uni. Stock
                                    </p>
                                </div>
                                <div v-if="disableInCedis" class="col-auto px-0 d-middle">
                                    <div :id="`loading-${index}`" class="d-middle text-general">
                                        <div class="">
                                            <i v-if="producto.cantidad <= 1" class="icon-trash-empty f-20 cr-pointer" @click="eliminarProductoDelkit(producto.id_hijo)" />
                                            <i v-else class="icon-minus f-20 cr-pointer" @click="sumarRestarCantidad(producto.id_hijo,-1)" />
                                        </div>
                                        <div class="text-center" style="width:30px;">
                                            {{ producto.cantidad }}
                                        </div>
                                        <div class="">
                                            <i class="icon-plus f-18 cr-pointer" @click="sumarRestarCantidad(producto.id_hijo,1)" />
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-agregar-productos ref="modalAgregarProductos" @recargarProductos="obtenerProductosDelKit" />
    </section>
</template>

<script>
import Productos from '~/services/productos/productos'
import {mapGetters} from 'vuex'
export default {
    
    components: {
        modalAgregarProductos: () => import('../partials/modalAgregarProductos')
    },
    data(){
        return {
            img_producto: 'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
            cantProd: 0,
            productos: []
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            is_kit: 'productos/productos/is_kit',
        }),
        disableInCedis(){
            return !(this.$route.name==='admin.cedis.combos-kits')
        }
    },
    watch:{
        id_producto(val){
            this.obtenerProductosDelKit()
        },
        id_cedis(val){
            this.obtenerProductosDelKit()
        }
    },
    methods: {
        modalAgregar(){
            this.$refs.modalAgregarProductos.toggle(this.id_producto);
        },
        async obtenerProductosDelKit(){
            try {
                if(this.id_producto){
                    let loading = this.$loading(
                        {
                            lock: true,
                            target:`#loading-productos`
                        });

                    const { data } = await Productos.productosDelKit(this.id_producto)
                    this.productos = data
                    this.$emit("productosObtenidos",data)
                    loading.close()
                }                
            } catch (error){
                this.error_catch(error)
            }
        }, 

        async eliminarProductoDelkit(id_producto){
            try {
                if(this.id_producto){
                    let loading = this.$loading(
                        {
                            lock: true,
                            target:`#loading-productos`
                        });

                    let params = {
                        id_producto_padre : this.id_producto,
                        id_producto_hijo : id_producto,
                    }

                    const { data } = await Productos.eliminarProductoDelkit(params)
                    this.productos = data
                    this.obtenerProductosDelKit()
                    loading.close()
                }                
            } catch (error){
                this.error_catch(error)
            }
        },
        
        async sumarRestarCantidad(id_producto,numero){
            try {
                if(this.id_producto){
                    let loading = this.$loading(
                        {
                            lock: true,
                            target:`#loading-productos`
                        });

                    let params = {
                        id_producto_padre : this.id_producto,
                        id_producto_hijo : id_producto,
                        numero : numero
                    }

                    const { data } = await Productos.sumarRestarCantidad(params)
                    this.productos = data
                    this.obtenerProductosDelKit()
                    loading.close()
                }                
            } catch (error){
                this.error_catch(error)
            }
        } 

    }
}
</script>